/*
 * @Author: xkf
 * @Date: 2021-07-19 16:29:51
 * @LastEditTime: 2022-03-15 15:43:32
 * @LastEditors: xkf
 * @Description:
 */
import "./index.scss";
import React from "react";
import ReactDOM from "react-dom";
import Routers from "./router/Routers";
import reportWebVitals from "./reportWebVitals";
import Ctx from "./store/ctx";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

ReactDOM.render(
	<React.StrictMode>
		<Ctx.Provider value={{ data: { demo: true } }}>
			<Routers />
		</Ctx.Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
reportWebVitals();
