/*
 * @Author: xkf
 * @Date: 2021-10-25 16:42:16
 * @LastEditTime: 2021-12-18 17:37:20
 * @LastEditors: xkf
 * @Description: store
 */
import { createContext } from "react";
const Ctx = createContext({});
export default Ctx;
