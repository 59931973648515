/*
 * @Author: xkf
 * @Date: 2021-07-19 16:41:25
 * @LastEditTime: 2022-02-22 10:07:22
 * @LastEditors: xkf
 * @Description:
 */
import { lazy } from "react";
// import { Redirect } from "react-router-dom";
const Transition = lazy(
	() => import(/* webpackChunkName: "app" */ "@/pages/transition/Transition")
);
const Index = lazy(
	() => import(/* webpackChunkName: "app" */ "@/pages/index/Index")
);
const H5 = lazy(() => import(/* webpackChunkName: "app" */ "@/pages/h5/Index"));

const RouteConfig: any = [
	{
		path: "/",
		exact: true,
		component: Transition,
	},
	{
		path: "/pc",
		exact: true,
		component: Index,
	},
	{
		path: "/h5",
		exact: true,
		component: H5,
	},
];

export default RouteConfig;
